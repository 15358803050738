export const topographer = [
  {
    id: 1,
    model: "ANTARES +",
    data: [
      {
        id: 1,
        logo: "/images/cataract/ufsk.png",
        model: "ANTARES + Corneal Topographer",
        // name: "Treatment Table",
        main_points: [
          "Various maps",
          "Keratoconus screening",
          "Videokeratoscopy",
          "Removable push handles & armrests",
          "Optimal working conditions for the surgeon",
          "Robust steel chassis with four double castors",
        ],
        additionalInfo: [
          {
            id: 1,
            title: "Publication",
          },
          {
            id: 2,
            title: "Brochures",
          },
          {
            id: 3,
            title: "Technical Data (Compare)",
          },
        ],
        img: ["/images/refractive/ANTARES-0007.png"],
        video: [
          {
            imggallery: [
              "/images/cataract/imggallery_1.png",
              "/images/cataract/imggallery_2.png",
              "/images/cataract/imggallery_3.png",
            ],
            videoSrc: "/images/cataract/overview.png",
            videoTitle: "Contact Lens Application",
          },
        ],
      },
    ],
  },
  {
    id: 2,
    model: "SIRIUS +",
    data: [
      {
        id: 1,
        logo: "/images/cataract/ufsk.png",
        model: "SIRIUS +",
        name: "Scheimpflug with Placido disk Topography",
        main_points: [
          "Measurements up to 100 HR corneal sections.",
          "Various maps (Front & Back of the cornea)",
          "Early Keratoconus screening with ABCD grading scale",
          "Ambrosio pachymetry progression map",
          "Corneal aberrometer",
          "Robust steel chassis with four double castors",
          "Glaucoma screening",
          "Intrastromal ring",
        ],
        additionalInfo: [
          {
            id: 1,
            title: "Publication",
          },
          {
            id: 2,
            title: "Brochures",
          },
          {
            id: 3,
            title: "Technical Data (Compare)",
          },
        ],
        img: ["/images/refractive/cso_16075055325571.png"],
        video: [
          {
            imggallery: [
              "/images/cataract/imggallery_1.png",
              "/images/cataract/imggallery_2.png",
              "/images/cataract/imggallery_3.png",
            ],
            videoSrc: "/images/cataract/overview.png",
            videoTitle: "Compact Setup",
          },
        ],
      },
    ],
  },
  {
    id: 3,
    model: "TMS-4N",
    data: [
      {
        id: 1,
        logo: "/images/cataract/ufsk.png",
        model: "TMS-4N",
        name: "Corneal Topographer",
        main_points: [
          "Built-in LCD screen",
          "Various maps",
          "Keratoconus screening",
          "Fourier analysis",
        ],
        additionalInfo: [
          {
            id: 1,
            title: "Publication",
          },
          {
            id: 2,
            title: "Brochures",
          },
          {
            id: 3,
            title: "Technical Data (Compare)",
          },
        ],
        img: ["/images/refractive/TMS_4N_1.png"],
        // video: [
        //   {
        //       imggallery: [
        //         "/images/cataract/imggallery_1.png",
        //         "/images/cataract/imggallery_2.png",
        //         "/images/cataract/imggallery_3.png",
        //       ],
        //     videoSrc: "/images/cataract/overview.png",
        //     videoTitle: "Compact Setup",
        //   },
        // ],
      },
    ],
  },
];

export const microkeratome = [
  {
    id: 1,
    logo: "/images/refractive/logo-moria-white.png",
    model: "Moria Evo 3e console with SBK set",
    //   name: "Osiris-T provides a corneal topographer combined with a total ocular aberrometer",
    main_points: [
      "Based unique pyramid sensor",
      "Real time Ocular wavefront measurement",
      "Densitometer",
      "Dynamic accommodation",
      "Toric lens assistance",
      "Pupillography",
      "Advance analysis of Tear film",
      "Various maps and analysis",
    ],
    additionalInfo: [
      {
        id: 1,
        title: "Publications",
      },
      {
        id: 2,
        title: "Brochures",
      },
      {
        id: 3,
        title: "Technical Data (Compare)",
      },
    ],
    img: ["/images/refractive/e779c2270408b19d962770cc05614a25b835b607.png"],
    video: [
      {
        imggallery: ["/images/refractive/Scroll Group 1.png"],
        videoSrc: "/images/refractive/Screenshot (55).png",
        videoTitle: "SBK Surgical Process",
        videoUrl: "https://youtu.be/tADaSl_HtqA",
      },
    ],
  },
];

export const asoct = [
  {
    id: 1,
    model: "MS-39",
    data: [
      {
        id: 1,
        logo: "/images/cataract/ufsk.png",
        model: "MS-39",
        // name: "Treatment Table",
        main_points: [
          "HR corneal sections images on a diameter of 16 mm",
          "Epithelial & stromal map",
          "Early Keratoconus screening with ABCD grading",
          "Corneal aberrometer",
          "Optimal working conditions for the surgeon",
          "Robust steel chassis with four double castors",
          "IOL calculation with Ray tracing",
        ],
        additionalInfo: [
          {
            id: 1,
            title: "Publication",
          },
          {
            id: 2,
            title: "Brochures",
          },
          {
            id: 3,
            title: "Technical Data (Compare)",
          },
        ],
        img: ["/images/refractive/MS-39-9.png"],
        video: [
          {
            imggallery: [
              "/images/cataract/imggallery_1.png",
              "/images/cataract/imggallery_2.png",
              "/images/cataract/imggallery_3.png",
            ],
            videoSrc: "/images/refractive/Crustalline biometery.png",
            videoTitle: "Crustalline Biometery",
          },
        ],
      },
    ],
  },
  {
    id: 2,
    model: "CASIA2",
    data: [
      {
        id: 1,
        logo: "/images/cataract/ufsk.png",
        model: "CASIA2",
        // name: "Treatment Table",
        main_points: [
          "HR corneal sections images on a diameter of 16 mm",
          "Epithelial & stromal map",
          "Early Keratoconus screening with ABCD grading",
          "Corneal aberrometer",
          "Optimal working conditions for the surgeon",
          "Robust steel chassis with four double castors",
          "IOL calculation with Ray tracing",
        ],
        additionalInfo: [
          {
            id: 1,
            title: "Publication",
          },
          {
            id: 2,
            title: "Brochures",
          },
          {
            id: 3,
            title: "Technical Data (Compare)",
          },
        ],
        img: ["/images/refractive/CASIA2-set-(high-resolution).png"],
        video: [
          {
            imggallery: [
              "/images/cataract/imggallery_1.png",
              "/images/cataract/imggallery_2.png",
              "/images/cataract/imggallery_3.png",
            ],
            videoSrc: "/images/refractive/Crustalline biometery.png",
            videoTitle: "Crustalline Biometery",
          },
        ],
      },
    ],
  },
];

export const keratoplasty = [
  {
    id: 1,
    model: "VACUUM TREPHINE & PUNCH",
    data: [
      {
        id: 1,
        logo: "/images/cataract/ufsk.png",
        model: "VACUUM TREPHINE & PUNCH",
        // name: "Treatment Table",
        main_points: [
          "HR corneal sections images on a diameter of 16 mm",
          "Epithelial & stromal map",
          "Early Keratoconus screening with ABCD grading",
          "Corneal aberrometer",
          "Optimal working conditions for the surgeon",
          "Robust steel chassis with four double castors",
          "IOL calculation with Ray tracing",
        ],
        additionalInfo: [
          {
            id: 1,
            title: "Publication",
          },
          {
            id: 2,
            title: "Brochures",
          },
          {
            id: 3,
            title: "Technical Data (Compare)",
          },
        ],
        img: [
          "/images/refractive/Vacuum-punch.png",
          "/images/refractive/Adjustable-vacuum-Trephine.png",
        ],
        video: [
          {
            imggallery: [
              "/images/refractive/Vacuum-punch.png",
              "/images/refractive/Adjustable-vacuum-Trephine.png",
              "/images/refractive/Adjustable-vacuum-Trephine.png",
              "/images/refractive/Adjustable-vacuum-Trephine.png",
              "/images/refractive/Vacuum-punch.png",
            ],
            videoSrc: "/images/refractive/Crustalline biometery.png",
            videoTitle: "Crustalline Biometery",
          },
        ],
      },
    ],
  },
  {
    id: 2,
    model: "DSAEK + ALTK SYSTEM",
    data: [
      {
        id: 1,
        logo: "/images/cataract/ufsk.png",
        model: "DSAEK + ALTK SYSTEM",
        // name: "Treatment Table",
        main_points: [
          "Linear system",
          "High degree of accuracy & reproducibility",
          "Range of pre-calibrated single use heads",
          "Corneal aberrometer",
          "For Ultra-Thin DSAEK , as well as conventional DSAEK",
          "Robust steel chassis with four double castors",
          "IOL calculation with Ray tracing",
        ],
        additionalInfo: [
          {
            id: 1,
            title: "Publication",
          },
          {
            id: 2,
            title: "Brochures",
          },
          {
            id: 3,
            title: "Technical Data (Compare)",
          },
        ],
        img: ["/images/refractive/image010.png"],
        video: [
          {
            imggallery: [
              "/images/cataract/imggallery_1.png",
              "/images/cataract/imggallery_2.png",
              "/images/cataract/imggallery_3.png",
            ],
            videoSrc: "/images/refractive/Crustalline biometery.png",
            videoTitle: "Crustalline Biometery",
          },
        ],
      },
    ],
  },
];

export const dryice = [
  {
    id: 1,
    model: "IDRA",
    data: [
      {
        id: 1,
        logo: "/images/refractive/SBM.png",
        model: "IDRA",
        // name: "Treatment Table",
        main_points: [
          "Quick-Accurate- Non-invasive",
          "15 sec of acquisition in fully automatic mode",
          "Integrated and easy to use",
          "Integrated and easy to use",
          // "Optimal working conditions for the surgeon",
          // "Robust steel chassis with four double castors",
          // "IOL calculation with Ray tracing",
        ],
        additionalInfo: [
          {
            id: 1,
            title: "Publication",
          },
          {
            id: 2,
            title: "Brochures",
          },
          {
            id: 3,
            title: "Technical Data (Compare)",
          },
        ],
        img: [
          "/images/refractive/_DSC8311-B-copia.png",
          // "/images/refractive/Adjustable-vacuum-Trephine.png",
        ],
        video: [
          {
            imggallery: [
              "/images/refractive/_DSC8311-B-copia.png",
              "/images/refractive/_DSC8311-B-copia.png",
              "/images/refractive/_DSC8311-B-copia.png",
              "/images/refractive/_DSC8311-B-copia.png",
            ],
            videoSrc: "/images/refractive/_DSC8839.png",
            videoTitle: "Product Application",
          },
        ],
      },
    ],
  },
  {
    id: 2,
    model: "ACTIVA",
    data: [
      {
        id: 1,
        logo: "/images/cataract/ufsk.png",
        model: "ACTIVA",
        // name: "Treatment Table",
        main_points: [
          "Safe and effective",
          "Long lasting relief",
          "Innovative eye mask",
          "Fast and painless",
          "Easily sanitized and no gel required",
        ],
        additionalInfo: [
          {
            id: 1,
            title: "Publication",
          },
          {
            id: 2,
            title: "Brochures",
          },
          {
            id: 3,
            title: "Technical Data (Compare)",
          },
        ],
        img: ["/images/refractive/image010.png"],
        video: [
          {
            imggallery: [
              "/images/refractive/_DSC8311-B-copia.png",
              "/images/refractive/Activa_2.png",
            ],
            videoSrc: "/images/refractive/Crustalline biometery.png",
            videoTitle: "Crustalline Biometery",
          },
        ],
      },
    ],
  },

  {
    id: 3,
    model: "TEARCHECK",
    data: [
      {
        id: 1,
        logo: "/images/cataract/ufsk.png",
        model: "TEARCHECK",
        // name: "Treatment Table",
        main_points: [
          "Simple and Fast",
          "9 exams for smart analysis",
          "Innovative eye mask",
        ],
        additionalInfo: [
          {
            id: 1,
            title: "Publication",
          },
          {
            id: 2,
            title: "Brochures",
          },
          {
            id: 3,
            title: "Technical Data (Compare)",
          },
        ],
        img: ["/images/refractive/111A1969.png"],
        video: [
          {
            imggallery: [
              "/images/refractive/111A1969.png",
              "/images/refractive/111A1969.png",
              "/images/refractive/111A1969.png",
              "/images/refractive/111A1969.png",
            ],
            videoSrc: "/images/refractive/_DSC0149.png",
            videoTitle: "Crustalline Biometery",
          },
        ],
      },
    ],
  },

  {
    id: 4,
    model: "E-EYE",
    data: [
      {
        id: 1,
        logo: "/images/cataract/ufsk.png",
        model: "E-EYE",
        // name: "Treatment Table",
        main_points: [
          "IRPL technology",
          "Simple and Fast",
          "Instant improvement",
          "Long lasting",
        ],
        additionalInfo: [
          {
            id: 1,
            title: "Publication",
          },
          {
            id: 2,
            title: "Brochures",
          },
          {
            id: 3,
            title: "Technical Data (Compare)",
          },
        ],
        img: ["/images/refractive/E-Eye-profile_web.png"],
        video: [
          {
            imggallery: ["/images/refractive/E-Eye-profile_web.png"],
            videoSrc: "/images/refractive/_DSC0149.png",
            // videoTitle: "Crustalline Biometery",
          },
        ],
      },
    ],
  },
];

export const specular = [
  {
    id: 1,
    logo: "/images/cataract/CSO.png",
    model: "EM-4000",
    // name: "Osiris-T provides a corneal topographer combined with a total ocular aberrometer",
    main_points: [
      "Fully automatic with touch screen",
      "13 measurement area",
      "L-count, Trace, Core method, Dark Area analysis",
      "Counts more than 300 cells",
      "Integrated Non-contact pachy",
    ],
    additionalInfo: [
      // {
      //   id: 1,
      //   title: "Publications",
      // },
      {
        id: 2,
        title: "Brochures",
      },
      {
        id: 3,
        title: "Technical Data (Compare)",
      },
    ],
    img: ["/images/refractive/_0-3-EM_4000_3_a.png"],
    video: [
      {
        imggallery: [
          "/images/refractive/_0-3-EM_4000_3_a.png",
          "/images/refractive/EM_4000_2.png",
          "/images/refractive/EM_4000_3.png",
        ],
        // videoSrc: "/images/cataract/overview.png",
        // videoTitle: "Upgradable to A-scan and UBM",
      },
    ],
  },
];
