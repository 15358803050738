export const navlinks = [
  {
    id: 1,
    name: "About Us",
    link: "/about-us",
  },
  {
    id: 2,
    name: "Our Products",
    // link: "/products",
  },
  {
    id: 3,
    name: "Contact Us",
    link: "/contact-us",
  },
];

export const ourProductsSublinks = [
  {
    id: 1,
    name: "RETINA & GLAUCOMA",
    link: "/products/retina-glaucoma",
  },
  {
    id: 2,
    name: "CORNEA & REFRACTIVE",
    link: "/products/cornea-refractive",
  },
  {
    id: 3,
    name: "CATARACT",
    link: "/products/cataract",
  },
  {
    id: 4,
    name: "PRIMARY DIAGNOSTICS",
    link: "/products/primary-diagonostics",
  },
  {
    id: 5,
    name: "STERILIZATION & INDICATORS",
    link: "/products/sterilization-indicators",
  },
];
