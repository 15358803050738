import React from "react";
import BreadCrumb from "../common/BreadCrumb";
import CenterHeading from "../common/CenterHeading";


const CategoryTopSection = ({ breadcrumb, centerHeading }) => {
 
  return (
    <div
      className="w-full  h-[81.33vw] md:h-[52.15vh] relative"
      style={{
        backgroundImage: `url("/images/category/bg.png")`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <BreadCrumb title={breadcrumb} />
      <CenterHeading title={centerHeading} />
    </div>
  );
};

export default CategoryTopSection;
