import { BsArrowLeft, BsArrowRight } from "react-icons/bs";
import React, { useEffect, useRef } from "react";
import SectionHeading from "../common/SectionHeading";
import ParaText from "../common/ParaText";
import Aos from "aos";

const OurHistory = () => {
  const historyData = [
    {
      id: 1,
      year: 1996,
      desc: "Established in 1996, we started our journey in the ophthalmic industry.",
    },
    {
      id: 2,
      year: 2000,
      desc: "Launched our first product line of surgical instruments and vision",
    },
    {
      id: 3,
      year: 2005,
      desc: "Expanded our operations to international markets and innovation.",
    },
    {
      id: 4,
      year: 2010,
      desc: "Introduced advanced diagnostic devices to enhance patient care.",
    },
    {
      id: 5,
      year: 2015,
      desc: "Achieved ISO certification for quality management systems.",
    },
    {
      id: 6,
      year: 2020,
      desc: "Collaborated with leading suppliers to innovate new products.",
    },
    {
      id: 7,
      year: 2021,
      desc: "Launched our online platform for easier access to our products.",
    },
    {
      id: 8,
      year: 2022,
      desc: "Received awards for excellence in service and quality.",
    },
    {
      id: 9,
      year: 2023,
      desc: "Received awards for excellence in service and quality.",
    },
    {
      id: 10,
      year: 2024,
      desc: "Expanded our product range to include telemedicine solutions.",
    },
    {
      id: 11,
      year: 2025,
      desc: "Achieved a significant milestone of serving 1 million patients.",
    },
    {
      id: 12,
      year: 2026,
      desc: "Celebrated our 30th anniversary with major industry recognition.",
    },
  ];

  const historyRef = useRef(null);

  useEffect(() => {
    Aos.init({
      easing: "ease-in",
      duration: 1500,
      once: false,
    });
  }, []);

  // Handler for scrolling left
  const scrollLeft = () => {
    if (historyRef.current) {
      historyRef.current.scrollBy({ left: -300, behavior: 'smooth' });
    }
  };

  // Handler for scrolling right
  const scrollRight = () => {
    if (historyRef.current) {
      historyRef.current.scrollBy({ left: 300, behavior: 'smooth' });
    }
  };

  return (
    <div
      className="w-full md:pt-[6.25vw]"
      data-aos="fade-up"
      data-aos-duration="1500"
      data-aos-offset="150"
    >
      <div className="flex items-start md:gap-x-55px-vw">
        <SectionHeading title={"OUR HISTORY"} customCss={"whitespace-nowrap"} />
        <ParaText
          paratext={
            "Established in 1996, My Healthskape Medicals Private Limited has been a leader in sourcing ophthalmic surgical equipment and diagnostic devices from various reputable international suppliers specializing in the ophthalmic industry."
          }
        />
      </div>
      <div className="w-full md:pt-86px-vw relative">
        <div className="border-[#393939]   border-[1px] border-opacity-30 border-solid"></div>
        <div className="border-[#2B2A29] top-[25%] left-[-2.7vw] absolute border border-solid rounded-full cursor-pointer p-16px-vw" onClick={scrollLeft}>
          <BsArrowLeft />
        </div>

        <div className="border-[#2B2A29] cursor-pointer top-[25%] right-[-2.7vw] absolute border border-solid rounded-full p-16px-vw" onClick={scrollRight}>
          <BsArrowRight />
        </div>

        <div className="flex items-center w-full overflow-x-auto space-x-50px-vw scrollbar-hidden" ref={historyRef}>
          {historyData.map((item) => (
            <div
              key={item.id}
              className="flex flex-col items-center text-[#050709] font-poppins justify-center gap-y-16px-vw flex-shrink-0 w-[250px]" // Adjust the width as necessary
            >
              <div className="bg-[#519A7F] rounded-full mt-[-0.1vw] w-16px-vw block z-10 h-16px-vw"></div>
              <div className="font-regular text-20px-vw opacity-80">
                {item.year}
              </div>
              <div className="font-medium text-center text-20px-vw opacity-80">
                {item.desc}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default OurHistory;
