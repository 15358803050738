import React from 'react'

const SectionHeading = ({title,onClick,customCss}) => {
  return (
    <h1 className={`text-[#2B2A29]  font-raleway font-bold opacity-100 md:text-32px-vw text-24px-mvw ${customCss}`} onClick={onClick}>
      {title}
    </h1>
  )
}

export default SectionHeading
