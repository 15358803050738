import Aos from "aos";
import React, { useEffect } from "react";
import CategoryTopSection from "../../category/CategoryTopSection";

const LasikLaserSystem = () => {
  useEffect(() => {
    Aos.init({
      easing: "ease-in",
      duration: 1500,
      once: false,
    });
  }, []);

  return (
    <div className="w-full relative">
      <CategoryTopSection
        breadcrumb={
          "Home | Products | Cornea & Refractive | Lasik Laser System"
        }
        centerHeading={"Lasik Laser System"}
      />

      <section
        className="grid md:grid-cols-2 gap-x-[8vw] md:my-100px-vw md:px-[7.29vw] "
        data-aos="fade-up"
        data-aos-duration="1500"
        data-aos-offset="150"
      >
        <div
          className="flex items-start flex-col justify-start gap-y-40px-vw
        "
        >
          <div>
            <img src={"/images/refractive/logo.png"} alt="logo.png" />
          </div>

          <div className="font-semibold text-48px-vw md:max-w-[80%]">
            Wavelight Refractive Suite EX 500 & FS 200
          </div>
          <div className="font-poppins font-regular text-20px-vw opacity-80 text-[#2B2A29] md:max-w-[80%]">
            Achieve outstanding visual outcomes with our integrated Wavelight
            Technology
          </div>
          <div>
            <img
              src="/images/refractive/Refractive-Suite.png"
              alt="refractive"
            />
          </div>
        </div>
        <div
          className="md:mt-[6vw] flex items-start flex-col justify-start gap-y-40px-vw 
        "
        >
          <div className="font-semibold text-48px-vw md:max-w-[80%]">
            Wavelight Topolyzer Vario
          </div>
          <div className="font-poppins font-regular text-20px-vw opacity-80 text-[#2B2A29] md:max-w-[80%]">
            Precision measurements for personalized treatment
          </div>
          <div>
            <img
              src="/images/refractive/Topolyzer-Vario.png"
              alt="refractive"
            />
          </div>
        </div>
      </section>
    </div>
  );
};

export default LasikLaserSystem;
