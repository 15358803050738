import React, { useEffect, useState } from "react";
import Marquee from "react-marquee-slider"; // Ensure you have this library installed
import SectionHeading from "../common/SectionHeading";
import Aos from "aos";

const OurPartners = ({ heading, CustomCss }) => {
  const [isPaused, setIsPaused] = useState(false); 
  const [velocity, setVelocity] = useState(40); 
  const PartnersImage = [
    { id: 1, imgsrc: "/logo/partner_logo_1.png" },
    { id: 2, imgsrc: "/logo/partner_logo_2.png" },
    { id: 3, imgsrc: "/logo/partner_logo_3.png" },
    { id: 4, imgsrc: "/logo/partner_logo_4.png" },
    { id: 5, imgsrc: "/logo/partner_logo_5.png" },
  ];

  useEffect(() => {
    Aos.init({
      easing: "ease-in-out",
      duration: 800,
      once: false,
      offset: 100,
    });
  }, []); 

  useEffect(() => {
    // Adjust velocity based on window width
    const handleResize = () => {
      if (window.innerWidth <= 375) { // Mobile breakpoint
        setVelocity(20); // Decrease speed for mobile
      } else {
        setVelocity(40); // Default speed for larger screens
      }
    };

    handleResize(); // Set initial velocity
    window.addEventListener("resize", handleResize); // Update on resize

    return () => {
      window.removeEventListener("resize", handleResize); // Cleanup listener
    };
  }, []);

  return (
    <section
      className={`w-full relative grid grid-cols-1 place-items-center pl-88px-vw pr-62px-vw ${CustomCss}`}
      data-aos="fade-up"
      data-aos-duration="1500"
      data-aos-offset="150"
    >
      <div className="text-center">
        <SectionHeading
          title={heading}
          customCss={"uppercase my-20px-mvw md:my-80px-vh"}
        />
      </div>
      <div
        className="relative overflow-hidden md:py-65px-vh py-35px-mvw bg-opacity-15 z-10 bg-white rounded-[4vw] md:rounded-[1.04vw]"
        onMouseEnter={() => setIsPaused(true)}
        onMouseLeave={() => setIsPaused(false)}
      >
        <Marquee
          velocity={velocity}
          minScale={0.8}
          className="partner-marquee"
          pauseOnHover={isPaused} 
        >
          {PartnersImage.map((item) => (
            <div key={item.id} className="flex-shrink-0 mx-4">
              <img
                src={item.imgsrc}
                alt={`Partner ${item.id}`}
                className="md:mr-[6.35vw] mr-32px-mvw md:h-[6vh] h-[3vh]"
              />
            </div>
          ))} 

        </Marquee> 
       
      </div> 
      <div
        className="relative overflow-hidden md:hidden block pb-35px-mvw bg-opacity-15 z-10 bg-white rounded-[4vw]"
        onMouseEnter={() => setIsPaused(true)}
        onMouseLeave={() => setIsPaused(false)}
      >
        <Marquee
          velocity={velocity}
          minScale={0.8} 
          direction="left"
          className="partner-marquee"
          pauseOnHover={isPaused} 
        >
          {PartnersImage.map((item) => (
            <div key={item.id} className="flex-shrink-0 mx-4">
              <img
                src={item.imgsrc}
                alt={`Partner ${item.id}`}
                className="md:mr-[6.35vw] mr-32px-mvw md:h-[6vh] h-[3vh]"
              />
            </div>
          ))} 

        </Marquee> 
       
      </div>
      <div className="absolute md:-left-[2.5vw] left-[-25vw] blur-[25px] opacity-70 -bottom-[11vh] md:-bottom-[13vh]">
        <img src="/images/home/eclipse.svg" alt="eclipse" />
      </div>
      <div className="absolute hidden md:block -right-50px-vw blur-[40px] opacity-70 bottom-40px-vw">
        <img src="/images/home/eclipse.svg" alt="eclipse" />
      </div>
    </section>
  );
};

export default OurPartners;
