export const oct = [
  {
    id: 1,
    model: "REVO FC 130",
    data: [
      {
        id: 1,
        logo: "/images/rectina/Optopol.png",
        model: "REVO FC 130",
        name: "Treatment Table",
        main_points: [
          "Efficient & Ergonomic",
          "Ergonomic lying surface",
          "Low entry position for the patient",
          "Removable push handles & armrests",
          "Optimal working conditions for the surgeon",
          "Robust steel chassis with four double castors",
        ],
        keyFeatureObject: {
          commonImg: "/images/rectina/REVO-FC-front-View.png",
          key_feature: [
            {
              id: 1,
              title: "ACCUTRACK",
              img: "/images/rectina/center_logo.png", // using common image
              desc: "Our hardware-based eye tracker, compensated for blinks, loss of fixation and involuntary eye movements during scans reducing artifacts",
            },
            {
              id: 2,
              title: "AUTO FUNCTIONS",
              img: "/images/rectina/center_logo.png",
              desc: "Our hardware-based eye tracker, compensated for blinks, loss of fixation and involuntary eye movements during scans reducing artifacts",
            },
            {
              id: 3,
              title: "A.I. DENOISE",
              img: "/images/rectina/center_logo.png",
              desc: "An advanced artificial intelligence algorithm removes noise from the tomogram for the highest image quality.",
            },
            {
              id: 4,
              title: "CUSTOM SCAN PROTOCOLS",
              img: "/images/rectina/center_logo.png",
              desc: "Save time and never miss a scan. Create a custom present group of scans and let the REVO capture all scans in order.",
            },
            {
              id: 5,
              title: "SMARTSCAN",
              img: "/images/rectina/center_logo.png",
              desc: "This feature enables intelligent scanning with real-time optimization for higher precision.",
            },
            {
              id: 6,
              title: "AUTO ALIGNMENT",
              img: "/images/rectina/center_logo.png",
              desc: "Automatically aligns the scan to ensure accuracy and reliability in scan results.",
            },
            {
              id: 7,
              title: "AI ENHANCED SCANNING",
              img: "/images/rectina/center_logo.png",
              desc: "Utilizes AI to enhance scan accuracy by automatically adjusting for eye movements.",
            },
            {
              id: 8,
              title: "EFFICIENT WORKFLOW",
              img: "/images/rectina/center_logo.png",
              desc: "Streamlines the scanning process, allowing for faster and more efficient scans without losing quality.",
            },
          ],
        },
        additionalInfo: [
          {
            id: 1,
            title: "Brochures",
          },
          {
            id: 2,
            title: "Technical Data (Compare)",
          },
        ],
        img: ["/images/rectina/FC130-New.png"],
        video: [
          {
            imggallery: [
              "/images/rectina/FC130-New.png",
              "/images/rectina/FC130-New.png",
              "/images/rectina/FC130-New.png",
              "/images/rectina/FC130-New.png",
              "/images/rectina/FC130-New.png",
            ],

            videoData: [
              {
                videothumbnail: "/images/cataract/overview.png",
                videoSrc: "https://youtu.be/AKa2ABRRgPs",
                videoTitle: "Non-Myd Color Fundus Camera",
                videoDesc:
                  "Capture high-resolution 45° true color retinal imaging. The REVO FC OCT series adds a 12.3 MP Fundus Camera, capable.",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    id: 2,
    model: "REVO FC ",
    data: [
      {
        id: 1,
        logo: "/images/cataract/ufsk.png",
        model: "REVO FC ",
        name: "Treatment Table",
        main_points: [
          "Streamlined & User-Friendly Design",
          "Comfortable and Supportive Surface",
          "Low-Height Access for Patients",
          "Detachable Push Handles and Armrests",
          "Enhanced Efficiency for Surgical Procedures",
          "Durable Steel Frame with Four Swivel Castors",
        ],

        keyFeatureObject: {
          commonImg: "/images/rectina/REVO-FC-front-View.png",
          key_feature: [
            {
              id: 1,
              title: "ACCUTRACK",
              img: "/images/rectina/center_logo.png", // using common image
              desc: "Our hardware-based eye tracker, compensated for blinks, loss of fixation and involuntary eye movements during scans reducing artifacts",
            },
            {
              id: 2,
              title: "AUTO FUNCTIONS",
              img: "/images/rectina/center_logo.png",
              desc: "Our hardware-based eye tracker, compensated for blinks, loss of fixation and involuntary eye movements during scans reducing artifacts",
            },
            {
              id: 3,
              title: "A.I. DENOISE",
              img: "/images/rectina/center_logo.png",
              desc: "An advanced artificial intelligence algorithm removes noise from the tomogram for the highest image quality.",
            },
            {
              id: 4,
              title: "CUSTOM SCAN PROTOCOLS",
              img: "/images/rectina/center_logo.png",
              desc: "Save time and never miss a scan. Create a custom present group of scans and let the REVO capture all scans in order.",
            },
            {
              id: 5,
              title: "SMARTSCAN",
              img: "/images/rectina/center_logo.png",
              desc: "This feature enables intelligent scanning with real-time optimization for higher precision.",
            },
            {
              id: 6,
              title: "AUTO ALIGNMENT",
              img: "/images/rectina/center_logo.png",
              desc: "Automatically aligns the scan to ensure accuracy and reliability in scan results.",
            },
            {
              id: 7,
              title: "AI ENHANCED SCANNING",
              img: "/images/rectina/center_logo.png",
              desc: "Utilizes AI to enhance scan accuracy by automatically adjusting for eye movements.",
            },
            {
              id: 8,
              title: "EFFICIENT WORKFLOW",
              img: "/images/rectina/center_logo.png",
              desc: "Streamlines the scanning process, allowing for faster and more efficient scans without losing quality.",
            },
          ],
        },
        additionalInfo: [
          {
            id: 1,
            title: "Brochures",
          },
          {
            id: 2,
            title: "Technical Data (Compare)",
          },
        ],
        img: ["/images/rectina/FC130-New.png"],
        video: [
          {
            imggallery: [
              "/images/rectina/FC130-New.png",
              "/images/rectina/FC130-New.png",
              "/images/rectina/FC130-New.png",
              "/images/rectina/FC130-New.png",
              "/images/rectina/FC130-New.png",
            ],

            videoData: [
              {
                videothumbnail: "/images/cataract/overview.png",
                videoSrc: "https://youtu.be/AKa2ABRRgPs",
                videoTitle: "Non-Myd Color Fundus Camera",
                videoDesc:
                  "Capture high-resolution 45° true color retinal imaging. The REVO FC OCT series adds a 12.3 MP Fundus Camera, capable.",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    id: 3,
    model: "REVO 60",
    data: [
      {
        id: 1,
        logo: "/images/cataract/ufsk.png",
        model: "REVO 60",
        name: "Treatment Table",
        main_points: [
  "Versatile & Comfortable Design",
  "Padded Surface for Patient Relaxation",
  "Low Access Height for Easy Patient Transfer",
  "Interchangeable Push Handles and Armrests",
  "Optimized Setup for Surgical Precision",
  "Reliable Steel Base with Maneuverable Castors",
],
        keyFeatureObject: {
          commonImg: "/images/rectina/REVO-FC-front-View.png",
          key_feature: [
            {
              id: 1,
              title: "ACCUTRACK",
              img: "/images/rectina/center_logo.png", // using common image
              desc: "Our hardware-based eye tracker, compensated for blinks, loss of fixation and involuntary eye movements during scans reducing artifacts",
            },
            {
              id: 2,
              title: "AUTO FUNCTIONS",
              img: "/images/rectina/center_logo.png",
              desc: "Our hardware-based eye tracker, compensated for blinks, loss of fixation and involuntary eye movements during scans reducing artifacts",
            },
            {
              id: 3,
              title: "A.I. DENOISE",
              img: "/images/rectina/center_logo.png",
              desc: "An advanced artificial intelligence algorithm removes noise from the tomogram for the highest image quality.",
            },
            {
              id: 4,
              title: "CUSTOM SCAN PROTOCOLS",
              img: "/images/rectina/center_logo.png",
              desc: "Save time and never miss a scan. Create a custom present group of scans and let the REVO capture all scans in order.",
            },
            {
              id: 5,
              title: "SMARTSCAN",
              img: "/images/rectina/center_logo.png",
              desc: "This feature enables intelligent scanning with real-time optimization for higher precision.",
            },
            {
              id: 6,
              title: "AUTO ALIGNMENT",
              img: "/images/rectina/center_logo.png",
              desc: "Automatically aligns the scan to ensure accuracy and reliability in scan results.",
            },
            {
              id: 7,
              title: "AI ENHANCED SCANNING",
              img: "/images/rectina/center_logo.png",
              desc: "Utilizes AI to enhance scan accuracy by automatically adjusting for eye movements.",
            },
            {
              id: 8,
              title: "EFFICIENT WORKFLOW",
              img: "/images/rectina/center_logo.png",
              desc: "Streamlines the scanning process, allowing for faster and more efficient scans without losing quality.",
            },
          ],
        },
        additionalInfo: [
          {
            id: 1,
            title: "Brochures",
          },
          {
            id: 2,
            title: "Technical Data (Compare)",
          },
        ],
        img: ["/images/rectina/FC130-New.png"],
        video: [
          {
            imggallery: [
              "/images/rectina/FC130-New.png",
              "/images/rectina/FC130-New.png",
              "/images/rectina/FC130-New.png",
              "/images/rectina/FC130-New.png",
              "/images/rectina/FC130-New.png",
            ],

            videoData: [
              {
                videothumbnail: "/images/cataract/overview.png",
                videoSrc: "https://youtu.be/AKa2ABRRgPs",
                videoTitle: "Non-Myd Color Fundus Camera",
                videoDesc:
                  "Capture high-resolution 45° true color retinal imaging. The REVO FC OCT series adds a 12.3 MP Fundus Camera, capable.",
              },
            ],
          },
        ],
      },
    ],
  },
];

export const perimeter = [
  {
    id: 1,
    model: "PTS 925W",
    data: [
      {
        id: 1,
        logo: "/images/cataract/ufsk.png",
        model: "PTS 925W",
        name: "Treatment Table",
        main_points: [
          "Efficient & Ergonomic",
          "Ergonomic lying surface",
          "Low entry position for the patient",
          "Removable push handles & armrests",
          "Optimal working conditions for the surgeon",
          "Robust steel chassis with four double castors",
        ],
        keyFeatureObject: {
          commonImg: "/images/rectina/Background.png",
          key_feature: [
            {
              id: 1,
              title: "HIGH-SPEED SCANNING",
              img: "/images/rectina/center_logo.png", // using common image
              desc: "Experience ultra-fast scanning speeds, enabling quicker results without compromising accuracy.",
            },
            {
              id: 2,
              title: "INTUITIVE CONTROL",
              img: "/images/rectina/center_logo.png",
              desc: "Easily control scanning parameters with an intuitive user interface for seamless operation.",
            },
            {
              id: 3,
              title: "PRECISION IMAGING",
              img: "/images/rectina/center_logo.png",
              desc: "Achieve high-resolution imaging with advanced optical systems for detailed scans.",
            },
            {
              id: 4,
              title: "MULTI-MODE SCANNING",
              img: "/images/rectina/center_logo.png",
              desc: "Switch between different scanning modes for tailored results, from rapid scans to deep imaging.",
            },
            {
              id: 5,
              title: "REAL-TIME ANALYSIS",
              img: "/images/rectina/center_logo.png",
              desc: "Analyze scan data in real-time with onboard processing for immediate feedback and adjustments.",
            },
            {
              id: 6,
              title: "USER-FRIENDLY DESIGN",
              img: "/images/rectina/center_logo.png",
              desc: "Ergonomically designed for easy use in clinical settings, providing comfort for both operators and patients.",
            },
            {
              id: 7,
              title: "ADVANCED AI SUPPORT",
              img: "/images/rectina/center_logo.png",
              desc: "Leverage AI-powered tools to enhance scan precision and automate repetitive tasks.",
            },
            {
              id: 8,
              title: "OPTIMIZED PERFORMANCE",
              img: "/images/rectina/center_logo.png",
              desc: "Optimized hardware and software integration ensures peak performance during high-demand scanning sessions.",
            },
          ],
        },
        additionalInfo: [
          {
            id: 1,
            title: "publications",
          },
          {
            id: 2,
            title: "Brochures",
          },
          {
            id: 3,
            title: "Technical Data (Compare)",
          },
        ],
        img: ["/images/rectina/20.-PTS-925W-(1).png"],
        video: [
          {
            imggallery: [
              "/images/rectina/20.-PTS-925W-(1).png",
              "/images/rectina/20.-PTS-925W-(1).png",
              "/images/rectina/20.-PTS-925W-(1).png",
              "/images/rectina/20.-PTS-925W-(1).png",
              "/images/rectina/20.-PTS-925W-(1).png",
            ],
            videoSrc: "/images/cataract/overview.png",
            videoTitle: "Non-Myd Color Fundus Camera",
            videoDesc:
              "Capture high-resolution 45° true color retinal imaging. The REVO FC OCT series adds a 12.3 MP Fundus Camera, capable .",
          },
        ],
      },
    ],
  },
  {
    id: 2,
    model: "PTS 925W",
    data: [
      {
        id: 1,
        logo: "/images/cataract/ufsk.png",
        model: "PTS 925W",
        name: "Treatment Table",
        main_points: [
          "Efficient & Ergonomic",
          "Ergonomic lying surface",
          "Low entry position for the patient",
          "Removable push handles & armrests",
          "Optimal working conditions for the surgeon",
          "Robust steel chassis with four double castors",
        ],
        keyFeatureObject: {
          commonImg: "/images/rectina/Background.png",
          key_feature: [
            {
              id: 1,
              title: "HIGH-SPEED SCANNING",
              img: "/images/rectina/center_logo.png", // using common image
              desc: "Experience ultra-fast scanning speeds, enabling quicker results without compromising accuracy.",
            },
            {
              id: 2,
              title: "INTUITIVE CONTROL",
              img: "/images/rectina/center_logo.png",
              desc: "Easily control scanning parameters with an intuitive user interface for seamless operation.",
            },
            {
              id: 3,
              title: "PRECISION IMAGING",
              img: "/images/rectina/center_logo.png",
              desc: "Achieve high-resolution imaging with advanced optical systems for detailed scans.",
            },
            {
              id: 4,
              title: "MULTI-MODE SCANNING",
              img: "/images/rectina/center_logo.png",
              desc: "Switch between different scanning modes for tailored results, from rapid scans to deep imaging.",
            },
            {
              id: 5,
              title: "REAL-TIME ANALYSIS",
              img: "/images/rectina/center_logo.png",
              desc: "Analyze scan data in real-time with onboard processing for immediate feedback and adjustments.",
            },
            {
              id: 6,
              title: "USER-FRIENDLY DESIGN",
              img: "/images/rectina/center_logo.png",
              desc: "Ergonomically designed for easy use in clinical settings, providing comfort for both operators and patients.",
            },
            {
              id: 7,
              title: "ADVANCED AI SUPPORT",
              img: "/images/rectina/center_logo.png",
              desc: "Leverage AI-powered tools to enhance scan precision and automate repetitive tasks.",
            },
            {
              id: 8,
              title: "OPTIMIZED PERFORMANCE",
              img: "/images/rectina/center_logo.png",
              desc: "Optimized hardware and software integration ensures peak performance during high-demand scanning sessions.",
            },
          ],
        },
        additionalInfo: [
          {
            id: 1,
            title: "publications",
          },
          {
            id: 2,
            title: "Brochures",
          },
          {
            id: 3,
            title: "Technical Data (Compare)",
          },
        ],
        img: ["/images/rectina/20.-PTS-925W-(1).png"],
        video: [
          {
            imggallery: [
              "/images/rectina/20.-PTS-925W-(1).png",
              "/images/rectina/20.-PTS-925W-(1).png",
              "/images/rectina/20.-PTS-925W-(1).png",
              "/images/rectina/20.-PTS-925W-(1).png",
              "/images/rectina/20.-PTS-925W-(1).png",
            ],
            videoSrc: "/images/cataract/overview.png",
            videoTitle: "Non-Myd Color Fundus Camera",
            videoDesc:
              "Capture high-resolution 45° true color retinal imaging. The REVO FC OCT series adds a 12.3 MP Fundus Camera, capable .",
          },
        ],
      },
    ],
  },
];

export const tonometer = [
  {
    id: 1,
    model: "HS-A900 F900 Z800",
    data: [
      {
        id: 1,
        logo: "/images/sterilization/hanshin.png",
        model: "A900 F900 Z800",
        name: "Vertical type Steam Sterilizer (60 Liters)",
        main_points: [
          "Ergonomic design",
          "Automatic door system",
          "Various sterilization cycles",
          "Self diagnosis program",
          "Cycle execution data storing",
        ],
        additionalInfo: [
          {
            id: 1,
            title: "Brochures",
          },
          {
            id: 2,
            title: "Technical Data (Compare)",
          },
        ],
        img: [
          "/images/Rectina/1.png",
          "/images/Rectina/2.png",
          "/images/Rectina/3.png",
        ],
        video: "",
      },
    ],
  },
  {
    id: 2,
    model: "FT 1000",
    data: [
      {
        id: 1,
        logo: "/images/sterilization/hanshin.png",
        model: "A900 F900 Z800",
        name: "Vertical type Steam Sterilizer (60 Liters)",
        main_points: [
          "Ergonomic design",
          "Automatic door system",
          "Various sterilization cycles",
          "Self diagnosis program",
          "Cycle execution data storing",
        ],
        additionalInfo: [
          {
            id: 1,
            title: "Brochures",
          },
          {
            id: 2,
            title: "Technical Data (Compare)",
          },
        ],
        img: ["/images/Rectina/FT-1000.png"],
        video: "",
      },
    ],
  },
];

export const pachymeter = [
  {
    id: 1,
    model: "Pachette 4",
    data: [
      {
        id: 1,
        logo: "/images/primary/tomey.png",
        model: "Pachette 4",
        name: "Auto Lensmeter",
        main_points: [
          "Hartmann sensor wavefront technology",
          "UV/Blue light measurement",
          "Lens mark recognition support",
          "Simple power mapping",
          "LAN & RS-232C connection",
        ],
        additionalInfo: [
          {
            id: 1,
            title: "Brochures",
          },
          {
            id: 2,
            title: "Technical Data (Compare)",
          },
        ],
        img: ["/images/rectina/Pachette 4.png"],
        video: [
          "/images/rectina/Mask Group 38.png",
          "/images/rectina/Turn-on-Power-Pachette-4.png",
        ],
      },
    ],
  },
  {
    id: 2,
    model: "Pachette 4",
    data: [
      {
        id: 1,
        logo: "/images/primary/tomey.png",
        model: "Pachette 4",
        name: "Auto Lensmeter",
        main_points: [
          "Hartmann sensor wavefront technology",
          "UV/Blue light measurement",
          "Lens mark recognition support",
          "Simple power mapping",
          "LAN & RS-232C connection",
        ],
        additionalInfo: [
          {
            id: 1,
            title: "Brochures",
          },
          {
            id: 2,
            title: "Technical Data (Compare)",
          },
        ],
        img: ["/images/rectina/Pachette 4.png"],
        video: [
          "/images/rectina/Mask Group 38.png",
          "/images/rectina/Turn-on-Power-Pachette-4.png",
        ],
      },
    ],
  },
];

export const erg = [
  {
    id: 1,
    model: "RETAVAL",
    data: [
      {
        id: 1,
        logo: "/images/cataract/ufsk.png",
        model: "RETAVAL",
        name: "Treatment Table",
        main_points: [
          "Efficient & Ergonomic",
          "Ergonomic lying surface",
          "Low entry position for the patient",
          "Removable push handles & armrests",
          "Optimal working conditions for the surgeon",
          "Robust steel chassis with four double castors",
        ],
        additionalInfo: [
          {
            id: 1,
            title: "Publications",
          },
          {
            id: 2,
            title: "Brochures",
          },
          {
            id: 3,
            title: "Technical Data (Compare)",
          },
        ],
        img: ["/images/rectina/RETeval-full-side-view_pupil.png"],
        video: [
          {
            imggallery: [
              "/images/rectina/RETeval-full-side-view_pupil.png",
              "/images/rectina/RETeval-full-side-view_pupil.png",
              "/images/rectina/RETeval-full-side-view_pupil.png",
            ],
            videoSrc: [
              "/images/rectina/Mask Group 38.png",
              "/images/rectina/Turn-on-Power-Pachette-4.png",
            ],
            videoTitle: "Overview Screen",
          },
        ],
      },
    ],
  },
  {
    id: 1,
    model: "RETAVAL",
    data: [
      {
        id: 1,
        logo: "/images/cataract/ufsk.png",
        model: "RETAVAL",
        name: "Treatment Table",
        main_points: [
          "Efficient & Ergonomic",
          "Ergonomic lying surface",
          "Low entry position for the patient",
          "Removable push handles & armrests",
          "Optimal working conditions for the surgeon",
          "Robust steel chassis with four double castors",
        ],
        additionalInfo: [
          {
            id: 1,
            title: "Publications",
          },
          {
            id: 2,
            title: "Brochures",
          },
          {
            id: 3,
            title: "Technical Data (Compare)",
          },
        ],
        img: ["/images/rectina/EP-1000Mf.png"],
        // video: [
        //   {
        //     imggallery: [
        //       "/images/rectina/RETeval-full-side-view_pupil.png",
        //       "/images/rectina/RETeval-full-side-view_pupil.png",
        //       "/images/rectina/RETeval-full-side-view_pupil.png",
        //     ],
        //     videoSrc: [
        //       "/images/rectina/Mask Group 38.png",
        //       "/images/rectina/Turn-on-Power-Pachette-4.png",
        //     ],
        //     videoTitle: "Overview Screen",
        //   },
        // ],
      },
    ],
  },
];

export const scanmate = [
  {
    id: 1,
    logo: "/images/cataract/CSO.png",
    model: "SCANMATE FLEX",
    name: "Osiris-T provides a corneal topographer combined with a total ocular aberrometer",
    main_points: [
      "Based unique pyramid sensor",
      "Real time Ocular wavefront measurement",
      "Densitometer",
      "Dynamic accommodation",
      "Toric lens assistance",
      "Pupillography",
      "Advance analysis of Tear film",
      "Various maps and analysis",
    ],
    additionalInfo: [
      {
        id: 1,
        title: "Publications",
      },
      {
        id: 2,
        title: "Brochures",
      },
      {
        id: 3,
        title: "Technical Data (Compare)",
      },
    ],
    img: ["/images/rectina/Scanmate-FLEX.png"],
    video: [
      {
        imggallery: ["/images/rectina/DGH-SHOOT-3-042-UBM.png"],
        videoSrc: "/images/cataract/overview.png",
        videoTitle: "Upgradable to A-scan and UBM",
      },
    ],
  },
];

export const ud = [
  {
    id: 1,
    logo: "/images/cataract/CSO.png",
    model: "UD-800",
    name: "Osiris-T provides a corneal topographer combined with a total ocular aberrometer",
    main_points: [
      "Based unique pyramid sensor",
      "Real time Ocular wavefront measurement",
      "Densitometer",
      "Dynamic accommodation",
      "Toric lens assistance",
    ],
    additionalInfo: [
      {
        id: 1,
        title: "Publications",
      },
      {
        id: 2,
        title: "Brochures",
      },
      {
        id: 3,
        title: "Technical Data (Compare)",
      },
    ],
    img: ["/images/rectina/UD-800_Side_0002.png"],
    video: [
      {
        imggallery: [
          "/images/rectina/UD-800_Side_0002.png",
          "/images/rectina/UD-800_Side_0002.png",
        ],
        videoSrc: "/images/cataract/overview.png",
        videoTitle: "Upgradable to A-scan and UBM",
      },
    ],
  },
];

export const fundus = [
  {
    id: 1,
    logo: "/images/cataract/CSO.png",
    model: "COBRA + FUNDUS CAMERA",
    name: "Osiris-T provides a corneal topographer combined with a total ocular aberrometer",
    main_points: [
      "Based unique pyramid sensor",
      "Real time Ocular wavefront measurement",
      "Densitometer",
      "Dynamic accommodation",
      "Toric lens assistance",
    ],
    additionalInfo: [
      {
        id: 1,
        title: "Publications",
      },
      {
        id: 2,
        title: "Brochures",
      },
      {
        id: 3,
        title: "Technical Data (Compare)",
      },
    ],
    img: ["/images/rectina/1.png"],
    video: [
      {
        imggallery: ["/images/rectina/1.png", "/images/rectina/2.png"],
        // videoSrc: "/images/cataract/overview.png",
        // videoTitle: "Upgradable to A-scan and UBM",
      },
    ],
  },
];
