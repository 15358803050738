import React from "react";
import CategoryTopSection from "../../components/category/CategoryTopSection";
import AboutTopSection from "../../components/aboutus/AboutTopSection";
import { data, sustainabilityData } from "../../Data/DummyData";
import OurHistory from "../../components/aboutus/OurHistory";
import Vision from "../../components/aboutus/Vision";
import Achievements from "../../components/aboutus/Achievements";
import OurTeam from "../../components/aboutus/OurTeam";
import OurPartners from "../../components/home/OurPartners";

const AboutUs = () => {
  return (
    <main className="w-full ">
      <CategoryTopSection
        breadcrumb={"Home | About Us"}
        centerHeading={"About Us"}
      />

      <section className="w-full  md:px-[7.29vw] md:py-[6.25vw]">
        <AboutTopSection heading={"ABOUT US"} desc={data} />
        <OurHistory />
        <Vision />
        <Achievements />
        <OurTeam />
        <div className="md:py-[6.25vw]">
          <AboutTopSection
            heading={"SUSTAINABILITY"}
            desc={sustainabilityData}
            CustomCss={"order-2 "}
          />  
        </div> 
        <OurPartners heading={"ALLIANCE PARTNERS"} CustomCss={"md:pl-0 md:pr-0"}/>
      </section>
    </main>
  );
};

export default AboutUs;
