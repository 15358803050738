import React from "react";
import CategoryTopSection from "../../category/CategoryTopSection";
import Listing from "../../category/Listing";

const Ubm = () => {
  const listingData = [
    {
      id: "01",
      name: "Scanmate Flex",
      logo: "/images/rectina/DGH.png",
      img: "/images/rectina/Scanmate-FLEX.png",
      link: "/products/cornea-refractive/scanmate-flex",
    },
    {
      id: "02",
      name: "UD-800",
      logo: "/images/rectina/DGH.png",
      img: "/images/rectina/UD-800_Side_0002.png",
      link: "/products/cornea-refractive/ud-800",
    },
  ];
  return (
    <section className="w-full relative">
      <CategoryTopSection
        breadcrumb={"Home | Products | Cornea & Refractive | UBM"}
        centerHeading={"UBM"}
      />
      <Listing data={listingData} />
    </section>
  );
};

export default Ubm;
