import React from 'react'
import CategoryTopSection from '../../components/category/CategoryTopSection'
import Listing from '../../components/category/Listing'

const PrimaryDiagnostics = () => { 
    const listingData = [
        {
          id: "01",
          name: "Slit-Lamp",
          img: "/images/cataract/cat-6.png", 
          link:"/products/primary-diagonostics/slit-lamp",
        },
        {
          id: "02",
          name: "Lensmeter",
          img: "/images/primary/pri-2.png", 
          link:"/products/primary-diagonostics/lensmeter",
        },
    ]
  return (
    <div>
         <section className='w-full relative'>
       <CategoryTopSection breadcrumb={"Home | Products | Primary Diagnostics"} centerHeading={"Primary Diagnostics"}/>
       <Listing heading={"PRIMARY DIAGNOSTICS PRODUCTS"} data={listingData}/>
    </section>
    </div>
  )
}

export default PrimaryDiagnostics