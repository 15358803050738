import React, { useEffect } from "react";
import SectionHeading from "../common/SectionHeading";
import ParaText from "../common/ParaText";
import Aos from "aos";

const AboutTopSection = ({ heading, desc, CustomCss }) => {
  useEffect(() => {
    Aos.init({
      easing: "ease-in",
      duration: 1500,
      once: false,
    });
  }, []);
  return (
    <div
      className={` w-full grid grid-cols-2 gap-x-80px-vw`}
      data-aos="fade-up"
      data-aos-duration="1500"
      data-aos-offset="150"
    >
      <div className={`w-full md:py-40px-vw  ${CustomCss}`}>
        <SectionHeading title={heading} />
        <ParaText paratext={desc} CustomCss={"md:mt-40px-vw"} />
      </div>
      <div className="bg-[#CACACA] w-full rounded-[1.5vw]"></div>
    </div>
  );
};

export default AboutTopSection;
