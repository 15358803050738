import { BsArrowLeft, BsArrowRight } from "react-icons/bs";
import React, { useRef, useEffect } from "react";
import SectionHeading from "../common/SectionHeading";
import ParaText from "../common/ParaText";
import TestimonialSlider from "./TestimonialSlider";
import Slider from "react-slick";
import Aos from "aos";

const Testimonial = () => {
  const sliderRef = useRef(null);

  // Testimonial data array
  const testimonials = [
    {
      quote:
        "The professors say things like ‘this is what you will have to do when you start your business,’ not ‘this section will be worth 5 marks in the midterms.",
      desc: "Furthermore, the BBA program provides you with all the information of business operations.",
      imgsrc: "/images/home/testimonial_img.png",
      name: "ARYNA SABALENKA",
      designation: "Business Analyst",
    },
    {
      quote:
        "This program helped me shape my entrepreneurial mindset. The real-world cases were amazing and beautiful.",
      desc: "The BBA program is a complete package for anyone aspiring to venture into the business world.",
      imgsrc: "/images/home/testimonial_img.png",
      name: "JOHN DOE",
      designation: "CEO & Founder",
    },
    {
      quote:
        "The support I received throughout the course was incredible, especially when it came to applying theory to real business scenarios.",
      desc: "With a strong focus on case studies, the BBA program encouraged practical thinking at every stage.",
      imgsrc: "/images/home/testimonial_img.png",
      name: "EMMA WILLIAMS",
      designation: "Marketing Specialist",
    },
    {
      quote:
        "Having hands-on experience in different business fields before graduation has given me a great advantage in my career.",
      desc: "The program has truly prepared me for the challenges of the corporate world.",
      imgsrc: "/images/home/testimonial_img.png",
      name: "DAVID SMITH",
      designation: "Operations Manager",
    },
    {
      quote:
        "The combination of theoretical learning and practical application in the BBA program made it truly engaging and impactful.",
      desc: "I felt ready to step into the professional world right after graduation thanks to the program’s practical focus.",
      imgsrc: "/images/home/testimonial_img.png",
      name: "LUCY BROWN",
      designation: "Financial Analyst",
    },
  ];

  // Slider settings
  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    centerMode: true, // Center the active slide
    centerPadding: "10%", // Partially show the next/previous slides
    slidesToScroll: 1,
    arrows: true, // Use custom buttons
    nextArrow: <BsArrowRight />,
    prevArrow: <BsArrowLeft />,
    beforeChange: (current, next) => {
      const allSlides = document.querySelectorAll(".slick-slide");
      allSlides.forEach((slide) => {
        slide.style.opacity = "0.4"; // Set opacity of all slides to less
      });
    },
    afterChange: (current) => {
      const activeSlide = document.querySelector(
        `.slick-slide[data-index="${current}"]`
      );
      if (activeSlide) {
        activeSlide.style.opacity = "1"; // Keep active slide full opacity
      }
    },
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          centerPadding: "10%",
        },
      },
      {
        breakpoint: 768, // Mobile devices
        settings: {
          slidesToShow: 1, // Show one slide
          centerMode: false,
        },
      },
    ],
  };

  useEffect(() => {
    // Initialize with active slide having full opacity
    const activeSlide = document.querySelector(`.slick-slide[data-index="0"]`);
    if (activeSlide) {
      activeSlide.style.opacity = "1";
    }
  }, []);

  const handlePrev = () => sliderRef.current.slickPrev();
  const handleNext = () => sliderRef.current.slickNext();

  useEffect(() => {
    Aos.init({
      easing: "ease-in",
      duration: 1500,
      once: false,
    });
  }, []);

  return (
    <section
      className="w-full relative md:pl-[7.29vw] px-4 py-80px-mvw   md:py-[8vh] grid md:grid-cols-[35%_65%] grid-cols-1"
      data-aos="fade-up"
      data-aos-duration="1500"
      data-aos-offset="150"
    >
      <div className="flex flex-col items-start  gap-y-30px-vh">
        <div>
          <SectionHeading
            title={"SPEAK TO OUR EXPERT"}
            customCss={"uppercase md:max-w-[80%]"}
          />
        </div>
        <div className="w-full md:max-w-[20.73vw] max-w-full">
          <ParaText
            paratext={
              "Experience advanced technology firsthand. Schedule an in-person demonstration with our expert to discover the benefits our products offer."
            }
          />
        </div>

        <div className="rounded-[4vw] border-[1px] border-[#2B2A29] md:flex items-center justify-center px-24px-vw py-18px-vh hidden  cursor-pointer mt-30px-vh">
          <div className="inline-flex text-center gap-x-10px-vw">
            <button
              className="text-[#2B2A29] font-poppins font-regular md:text-[1.24vw] text-40px-vw opacity-[0.8]"
              onClick={handlePrev}
            >
              <BsArrowLeft />
            </button>
            <button
              className="text-[#2B2A29] font-poppins md:text-[1.24vw] text-40px-vw opacity-[0.8]"
              onClick={handleNext}
            >
              <BsArrowRight />
            </button>
          </div>
        </div>
      </div>

      <div className="w-full">
        <Slider ref={sliderRef} {...sliderSettings}>
          {testimonials.map((testimonial, index) => (
            <TestimonialSlider
              key={index}
              testimonial={testimonial.quote}
              desc={testimonial.desc}
              imgsrc={testimonial.imgsrc}
              title={testimonial.name}
              designation={testimonial.designation}
            />
          ))}
        </Slider>

        <button className="w-full md:hidden flex items-center mt-20px-mvw  justify-center">
          <div className="rounded-[8vw] flex items-center border-[0.5px] border-[#2B2A29] opacity-100 px-[24px] py-[12px] space-x-[17px]">
            <div
              className="text-[#2B2A29] font-poppins font-regular md:text-[1.24vw] text-[20px] opacity-[0.8]"
              onClick={handlePrev}
            >
              <BsArrowLeft />
            </div>
            <div
              className="text-[#2B2A29] font-poppins font-regular md:text-[1.24vw] text-[20px] opacity-[0.8]"
              onClick={handleNext}
            >
              <BsArrowRight />
            </div>
          </div>
        </button>
      </div>
    </section>
  );
};

export default Testimonial;
