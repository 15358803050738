import React from "react";
import CategoryTopSection from "../../components/category/CategoryTopSection";
import Listing from "../../components/category/Listing";

const SterilizationIndicators = () => {
  const listingData = [
    {
      id: "01",
      name: "Steam Sterilizers",
      img: "/images/sterilization/str-1.png",
      link: "/products/sterilization-indicators/steam-sterilizers",
    },
    {
      id: "02",
      name: "Indicators",
      img: "/images/sterilization/str-2.png",
      link: "/products/sterilization-indicators/indicators",
    },
    {
      id: "02",
      name: "Packaging Material",
      img: "/images/sterilization/str-3.png",
      link: "/products/sterilization-indicators/packaging-material",
    },
  ];
  return (
    <section className="w-full relative">
      <CategoryTopSection
        breadcrumb={"Home | Products | Sterilization & Indicators"}
        centerHeading={"Sterilization & Indicators"}
      />
      <Listing
        heading={"STERILIZATION & INDICATORS PRODUCTS"}
        data={listingData}
      />
    </section>
  );
};

export default SterilizationIndicators;
