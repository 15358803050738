import React, { useEffect } from "react";
import SectionHeading from "../common/SectionHeading";
import Aos from "aos";

const Achievements = () => {
  useEffect(() => {
    Aos.init({
      easing: "ease-in",
      duration: 1500,
      once: false,
    });
  }, []);
  return (
    <main
      className="w-full"
      data-aos="fade-up"
      data-aos-duration="1500"
      data-aos-offset="150"
    >
      <h1 className="w-full text-center md:my-30px-vw">
        <SectionHeading title={"ACHIEVEMENTS"} />
      </h1>

      <div className="grid md:grid-cols-4 grid-cols-1 gap-x-30px-vw">
        {Array.from({ length: 4 }).map(
          (
            _,
            index // Use Array.from to create an array of length 4
          ) => (
            <div key={index} className="w-full ">
              <div className="bg-[#CACACA] min-h-[23vw] opacity-60 rounded-[1.5vw]">
                {/* Content can be added here */}
              </div>
              <div className="uppercase md:mt-[1vw] font-poppins font-medium md:text-24px-vw">
                ACHIEVEMENTS 01
              </div>
            </div>
          )
        )}
      </div>
    </main>
  );
};

export default Achievements;
