import React from "react";
import CategoryTopSection from "../../components/category/CategoryTopSection";
import Listing from "../../components/category/Listing";

const CorneaRefractive = () => {
  const listingData = [
    {
      id: "01",
      name: "Typographer",
      img: "/images/cornea/cor-1.png",
      link: "/products/cornea-refractive/topographer",
    },
    {
      id: "02",
      name: "Lasik Laser System",
      img: "/images/cornea/cor-2.png",
      link: "/products/cornea-refractive/lasik-laser-system",
    },
    {
      id: "03",
      name: "Microkeratome",
      img: "/images/cornea/cor-3.png",
      link: "/products/cornea-refractive/microkeratome",
    },
    {
      id: "04",
      name: "Pachymeter",
      img: "/images/cornea/cor-4.png",
      link: "/products/cornea-refractive/pachymeter",
    },
    {
      id: "05",
      name: "AS-OCT",
      img: "/images/cornea/cor-5.png",
      link: "/products/cornea-refractive/as-oct",
    },
    {
      id: "06",
      name: "Keratoplasty",
      img: "/images/cornea/cor-6.png",
      link: "/products/cornea-refractive/keratoplasty",
    },
    {
      id: "07",
      name: "Dry Eye",
      img: "/images/cornea/cor-7.png",
      link: "/products/cornea-refractive/dry-ice",
    },
    {
      id: "08",
      name: "Specular Microscope",
      img: "/images/cornea/cor-8.png",
      link: "/products/cornea-refractive/specular-microscope",
    },
    {
      id: "09",
      name: "UBM",
      img: "/images/cornea/cor-9.png",
      link: "/products/cornea-refractive/ubm",
    },
  ];

  return (
    <section className="w-full relative">
      <CategoryTopSection
        breadcrumb={"Home | Products | Cornea & Refractive"}
        centerHeading={"Cornea & Refractive"}
      />
      <Listing heading={"CORNEA & REFRACTIVE PRODUCTS"} data={listingData} />
    </section>
  );
};

export default CorneaRefractive;
