import { BsArrowLeft, BsArrowRight } from "react-icons/bs";
import React, { useEffect, useState } from "react";
import CategoryTopSection from "../../category/CategoryTopSection";
import DownloadCard from "../../common/DownloadCard";

import Aos from "aos";
import { perimeter } from "../../../Data/RectinaGlaucoma";
import Marquee from "react-marquee-slider";

const Perimeters = () => {
  const [isPaused, setIsPaused] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);

  const activeCategory = perimeter[activeIndex];

  useEffect(() => {
    Aos.init({
      easing: "ease-in",
      duration: 1500,
      once: false,
    });
  }, []);
  return (
    <main className="w-full relative">
      <CategoryTopSection
        breadcrumb={"Home | Products | Retina & Glaucoma | Perimeters"}
        centerHeading={"Perimeters"}
      />

      <section
        className="w-full  md:py-96px-vw md:px-[7.29vw]"
        data-aos="fade-up"
        data-aos-duration="1500"
        data-aos-offset="150"
      >
        <div className="flex items-center justify-center">
          <div className=" rounded-[4vw]  backdrop-blur-[30px] bg-white bg-opacity-60 md:flex md:flex-row flex-col items-start md:items-center justify-center space-x-60px-vw z-10  gap-y-40px-vh md:gap-y-0 p-24px-vw">
            {perimeter.map((item, index) => (
              <div
                key={item.id}
                className={`relative cursor-pointer ${
                  activeIndex === index
                    ? "opacity-100 font-bold backdrop-blur-[26px]   md:rounded-[3vw] rounded-[8vw] p-30px-vw py-12px-vh"
                    : "opacity-40 font-medium"
                }`}
                onClick={() => setActiveIndex(index)}
              >
                {activeIndex === index && (
                  <div className="absolute green-shadow z-0 border "></div>
                )}
                {/* Category name */}
                <div
                  className={`relative ${
                    activeIndex === index
                      ? "glass-bg w-[250px] h-[50px]   "
                      : ""
                  } flex items-center justify-center `}
                >
                  <span className="text-[#2b2b2b] font-poppins font-semibold md:text-[1.3vw] text-18px-mvw">
                    {item.model}
                  </span>
                </div>
              </div>
            ))}
          </div>
        </div>

        {activeCategory.data.map((item) => (
          <div key={item.id} className="w-full">
            <div className="grid md:grid-cols-2 gap-x-[6vw] grid-cols-1 md:py-80px-vw">
              <div className="flex flex-col font-poppins text-[#2B2A29] items-start justify-start gap-y-30px-vw">
                <div className="">
                  <img
                    src={item.logo}
                    alt="logo"
                    className="w-full h-full object-cover"
                  />
                </div>
                <div className="font-semibold text-48px-vw">{item.model}</div>
                <div className="font-regular text-20px-vw opacity-80">
                  {item.name}
                </div>
                <div className="font-semibold text-32px-vw">
                  PRODUCTS MAIN POINTS:
                </div>
                {item.main_points.map((item) => (
                  <div className="w-full flex items-center gap-x-20px-vw">
                    <div className="bg-[#519A7F] rounded-full md:w-14px-vw md:h-14px-vw"></div>
                    <div className="font-medium text-32px-vw">{item}</div>
                  </div>
                ))}
              </div>

              <div className="w-full h-full flex items-start justify-center">
                {item.img.map((img, index) => (
                  <div key={index} className=" ">
                    <img
                      src={img}
                      alt=""
                      className="w-full md:min-w-[32.75vw] object-cover"
                    />
                  </div>
                ))}
              </div>
            </div>

            <h1 className="font-raleway text-center font-bold md:text-32px-vw text-[#2B2A29]">
              PRODUCT KEY FEATURES
            </h1>
            <div className="w-full h-full md:my-60px-vw grid md:grid-cols-3 gap-20px-vw">
              {/* First Grid (First 4 objects) */}
              <div className="grid md:grid-cols-1 gap-20px-vw">
                {item.keyFeatureObject.key_feature.slice(0, 4).map((data) => (
                  <div
                    key={data.id}
                    className="flex flex-col font-poppins text-[#2B2A29] pb-60px-vw items-start justify-start"
                  >
                    <div className="flex items-center gap-x-20px-vw">
                      <div className="bg-[#519A7F] w-44px-vw h-44px-vw p-8px-vw rounded-full">
                        <img src={data.img} alt="img" />
                      </div>
                      <div className="text-24px-vw font-semibold">
                        {data.title}
                      </div>
                    </div>
                    <div className="font-regular text-20px-vw opacity-80 md:pt-24px-vw leading-relaxed">
                      {data.desc}
                    </div>
                  </div>
                ))}
              </div>

              <div className="flex items-center justify-center">
                <img src={item.keyFeatureObject.commonImg} alt="" />
              </div>

              {/* Second Grid (Next 4 objects) */}
              <div className="grid md:grid-cols-1  gap-20px-vw">
                {item.keyFeatureObject.key_feature.slice(4, 8).map((data) => (
                  <div
                    key={data.id}
                    className="flex flex-col font-poppins text-[#2B2A29] pb-60px-vw items-start justify-start"
                  >
                    <div className="flex items-center gap-x-20px-vw">
                      <div className="bg-[#519A7F] w-44px-vw h-44px-vw p-8px-vw rounded-full">
                        <img src={data.img} alt="img" />
                      </div>
                      <div className="text-24px-vw font-semibold">
                        {data.title}
                      </div>
                    </div>
                    <div className="font-regular text-20px-vw opacity-80 md:pt-24px-vw leading-relaxed">
                      {data.desc}
                    </div>
                  </div>
                ))}
              </div>
            </div>

            {
              <div className="w-full  gap-x-16px-vw md:pt-[3vw]  mx-auto flex items-center justify-center">
                <DownloadCard data={item.additionalInfo} />
              </div>
            }

            {item?.video &&
              item?.video.map((item) => (
                <div className="w-full flex relative flex-col items-center justify-center md:pt-[6.25vw]">
                  <h1 className="font-raleway font-bold md:text-32px-vw text-[#2B2A29]">
                    PRODUCT GALLERY
                  </h1>

                  {/* Image Gallery Section */}
                  <div
                    className="w-full flex md:py-60px-vw items-center  justify-center gap-x-60px-vw"
                    onMouseEnter={() => setIsPaused(true)}
                    onMouseLeave={() => setIsPaused(false)}
                  >
                    <Marquee
                      velocity={50}
                      minScale={0.8}
                      className="partner-marquee"
                      direction="left"
                      pauseOnHover={isPaused}
                    >
                      {/* Loop over imggallery array */}
                      {item.imggallery.map((imgSrc, index) => (
                        <img
                          key={index}
                          src={imgSrc}
                          alt={`imgGallery-${index}`}
                          className="md:max-w-[24.23vw] md:px-20px-vw object-cover"
                        />
                      ))}
                    </Marquee>
                  </div>

                  {/* Separator Line */}
                  <div className="absolute left-0 md:w-full md:mb-40px-vw border-b-2 opacity-10 border-[#2B2A29] z-10"></div>

                  {/* Video and Image Navigation */}
                  <div className="md:pt-60px-vw flex flex-col items-start relative">
                    <div className="flex items-center md:gap-x-30px-vw">
                      {/* Left Arrow with Gallery Image */}
                      <div className="relative">
                        <img
                          src={item.imggallery[0]}
                          alt="imggallery"
                          className="md:p-40px-vw w-full h-full object-cover"
                        />
                        <div className="absolute inset-0 rounded-[1.5vw] bg-[#2B2A29] opacity-60"></div>
                        <div className="absolute inset-0 flex w-full h-full items-center justify-center">
                          <div className="border border-solid rounded-full border-white p-30px-vw">
                            <BsArrowLeft className="md:text-[2vw] text-white" />
                          </div>
                        </div>
                      </div>

                      {/* Video Section */}
                      <div className="flex items-start flex-col justify-start">
                        <img
                          src={item.videoSrc}
                          alt="video"
                          className="md:max-w-[42.24vw] block"
                        />
                        <div className="md:pt-20px-vw text-[#2B2A29] font-poppins font-medium text-32px-vw">
                          {item.videoTitle}
                        </div>
                        <div className="font-poppins md:pt-20px-vw font-regular text-20px-vw text-[#2B2A29] opacity-80">
                          {item.videoDesc}
                        </div>
                      </div>

                      {/* Right Arrow with Gallery Image */}
                      <div className="relative">
                        <img
                          src={item.imggallery[1]}
                          alt="imggallery"
                          className="md:p-40px-vw w-full h-full object-cover"
                        />
                        <div className="absolute inset-0 rounded-[1.5vw] bg-[#2B2A29] opacity-60"></div>
                        <div className="absolute inset-0 flex w-full h-full items-center justify-center">
                          <div className="border border-solid rounded-full border-white p-30px-vw">
                            <BsArrowRight className="md:text-[2vw] text-white" />
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* Play Button */}
                    <div className="absolute cursor-pointer inset-0 flex items-center justify-center">
                      <img
                        src="/icon/playIcon.png"
                        alt="play icon"
                        className="md:w-73px-vw"
                      />
                    </div>
                  </div>
                </div>
              ))}
          </div>
        ))}
      </section>
    </main>
  );
};

export default Perimeters;
