import { BsArrowLeft, BsArrowRight } from "react-icons/bs";
import React, { useEffect, useState } from "react";
import CategoryTopSection from "../../category/CategoryTopSection";
import DownloadCard from "../../common/DownloadCard";

import Aos from "aos";
import { keratoplasty } from "../../../Data/CorneaRefractive";
import Marquee from "react-marquee-slider";

const Keratoplasty = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const activeCategory = keratoplasty[activeIndex];
  const [isPaused, setIsPaused] = useState(false);

  useEffect(() => {
    Aos.init({
      easing: "ease-in",
      duration: 1500,
      once: false,
    });
  }, []);
  return (
    <main className="w-full relative">
      <CategoryTopSection
        breadcrumb={"Home | Products | Cornea & Refractive | Keratoplasty"}
        centerHeading={"Keratoplasty"}
      />

      <section
        className="w-full  md:py-96px-vw md:px-[7.29vw]"
        data-aos="fade-up"
        data-aos-duration="1500"
        data-aos-offset="150"
      >
        <div className="flex items-center justify-center">
          <div className=" rounded-[4vw]  backdrop-blur-[30px] bg-white bg-opacity-60 md:flex md:flex-row flex-col items-start md:items-center justify-center space-x-60px-vw z-10  gap-y-40px-vh md:gap-y-0 p-24px-vw">
            {keratoplasty.map((item, index) => (
              <div
                key={item.id}
                className={`relative cursor-pointer ${
                  activeIndex === index
                    ? "opacity-100 font-bold  md:rounded-[3vw] rounded-[8vw] p-30px-vw py-12px-vh"
                    : "opacity-40 font-medium"
                }`}
                onClick={() => setActiveIndex(index)}
              >
                {activeIndex === index && (
                  <div className="absolute green-shadow z-0 border "></div>
                )}
                {/* Category name */}
                <div
                  className={`relative ${
                    activeIndex === index
                      ? "glass-bg w-[380px] h-[50px]   "
                      : ""
                  } flex items-center justify-center `}
                >
                  <span className="text-[#2b2b2b] font-poppins font-semibold md:text-[1.3vw] text-18px-mvw">
                    {item.model}
                  </span>
                </div>
              </div>
            ))}
          </div>
        </div>

        {activeCategory.data.map((item) => (
          <div key={item.id} className="w-full">
            <div className="grid md:grid-cols-2 gap-x-[6vw] grid-cols-1 md:py-80px-vw">
              <div className="flex flex-col font-poppins text-[#2B2A29] items-start justify-start gap-y-30px-vw">
                <div className="">
                  <img
                    src={item.logo}
                    alt="logo"
                    className="w-full h-full object-cover"
                  />
                </div>
                <div className="font-semibold text-48px-vw">{item.model}</div>
                <div className="font-regular text-20px-vw opacity-80">
                  {item.name}
                </div>
                <div className="font-semibold text-32px-vw">
                  PRODUCTS MAIN POINTS:
                </div>
                {item.main_points.map((item) => (
                  <div className="w-full flex items-center gap-x-20px-vw">
                    <div className="bg-[#519A7F] rounded-full md:w-14px-vw md:h-14px-vw"></div>
                    <div className="font-medium text-32px-vw">{item}</div>
                  </div>
                ))}
              </div>

              <div className="w-full h-full flex items-start justify-center gap-x-80px-vw  ">
                {item.img.map((img, index) => (
                  <div key={index} className=" ">
                    <img
                      src={img}
                      alt=""
                      className="w-full md:max-w-[32.13vw] object-cover"
                    />
                  </div>
                ))}
              </div>
            </div>

            {
              <div className="w-full   gap-x-16px-vw md:pt-[3vw]  mx-auto flex items-center justify-center">
                <DownloadCard data={item.additionalInfo} />
              </div>
            }
            {item?.video &&
              item?.video.map((item) => (
                <div className="w-full flex relative flex-col items-center justify-center md:pt-[6.25vw]">
                  <h1 className="font-raleway font-bold md:text-32px-vw text-[#2B2A29]">
                    IMAGE & VIDEO GALLERY
                  </h1>

                  {/* Image Gallery Section */}
                  <div
                    className="w-full flex md:py-60px-vw items-center justify-center gap-x-4"
                    onMouseEnter={() => setIsPaused(true)}
                    onMouseLeave={() => setIsPaused(false)}
                  >
                    <Marquee
                      velocity={50}
                      minScale={0.8}
                      className="partner-marquee"
                      direction="left"
                      pauseOnHover={isPaused}
                    >
                      {item?.imggallery &&
                        item?.imggallery.map((imgSrc, index) => (
                          <img
                            key={index}
                            src={imgSrc}
                            alt={`imgGallery-${index}`}
                            className="md:max-w-[18.23vw] object-cover"
                          />
                        ))}
                    </Marquee>
                  </div>

                  {/* Separator Line */}
                  <div className="absolute left-0 md:w-full md:mb-40px-vw border-b-2 opacity-10 border-[#2B2A29] z-10"></div>

                  {/* Video and Image Navigation */}
                  <div className="md:pt-60px-vw flex flex-col items-start relative">
                    <div className="flex items-center md:gap-x-30px-vw">
                      {/* Left Arrow with Gallery Image */}
                      <div className="relative">
                        <img
                          src={item.imggallery[0]}
                          alt="imggallery"
                          className="md:p-40px-vw w-full h-full object-cover"
                        />
                        <div className="absolute inset-0 rounded-[1.5vw] bg-[#2B2A29] opacity-60"></div>
                        <div className="absolute inset-0 flex w-full h-full items-center justify-center">
                          <div className="border border-solid rounded-full border-white p-30px-vw">
                            <BsArrowLeft className="md:text-[2vw] text-white" />
                          </div>
                        </div>
                      </div>

                      {/* Video Section */}
                      <div>
                        <img
                          src={item.videoSrc}
                          alt="video"
                          className="md:max-w-[42.24vw] block"
                        />
                        <div className="md:pt-20px-vw text-[#2B2A29] font-poppins font-medium text-32px-vw">
                          {item.videoTitle}
                        </div>
                      </div>

                      {/* Right Arrow with Gallery Image */}
                      <div className="relative">
                        <img
                          src={item.imggallery[1]}
                          alt="imggallery"
                          className="md:p-40px-vw w-full h-full object-cover"
                        />
                        <div className="absolute inset-0 rounded-[1.5vw] bg-[#2B2A29] opacity-60"></div>
                        <div className="absolute inset-0 flex w-full h-full items-center justify-center">
                          <div className="border border-solid rounded-full border-white p-30px-vw">
                            <BsArrowRight className="md:text-[2vw] text-white" />
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* Play Button */}
                    <div className="absolute cursor-pointer inset-0 flex items-center justify-center">
                      <img
                        src="/icon/playIcon.png"
                        alt="play icon"
                        className="md:w-73px-vw"
                      />
                    </div>
                  </div>
                </div>
              ))}
          </div>
        ))}
      </section>
    </main>
  );
};

export default Keratoplasty;
