export const UltrasoundAScanData = [
  {
    id: 1,
    model: "AL 4000",
    data: [
      {
        id: 1,
        logo: "/images/cataract/TOMEY.png",
        model: "AL 4000",
        name: "Ultrasound A-Scan + Pachymeter",
        main_points: [
          "Extremely handy & compact",
          "Easy to use",
          "Multiple IOL power calculation",
          "Contact & Immersion mode",
          "Bluetooth connectivity",
        ],
        additionalInfo: [
          {
            id: 1,
            title: "Brochures",
          },
          {
            id: 2,
            title: "Technical Data (Compare)",
          },
        ],
        img: ["/images/cataract/AL-4000.png"],
        video: [
          {
            videoSrc: "/images/cataract/video_1.png",
            videoTitle: "How to Use AL 4000",
            videoUrl: "https://youtu.be/tADaSl_HtqA",
          },
        ],
      },
    ],
  },
  {
    id: 2,
    model: "Scanmate A",
    data: [
      {
        id: 1,
        logo: "/images/cataract/DGH.png",
        model: "Scanmate A",
        name: "The innovative DGH 6000 A-Scan (Scanmate A) offers clinicians an unmatched level of usability and accuracy.",
        main_points: [
          "Ultra portable",
          "Unique measurement guidance",
          "Unique grading algorithm",
          "Contact & immersion mode",
          "Compression lock-out",
          "Multiple IOL power calculation",
        ],
        additionalInfo: [
          {
            id: 1,
            title: "Brochures",
          },
          {
            id: 2,
            title: "Technical Data (Compare)",
          },
        ],
        img: ["/images/cataract/Scanmate.png"],
        video: [
          {
            videoSrc: "/images/primary/video_thumb.png",
            videoTitle: "How to Use AL 4000",
            videoUrl: "https://youtu.be/tADaSl_HtqA",
          },
        ],
      },
    ],
  },
];

export const opticalBiometerData = [
  {
    id: 1,
    logo: "/images/cataract/TOMEY.png",
    model: "OA 2000",
    name: "Swept Source Optical Biometer with Topography",
    main_points: [
      "Fully automatic",
      "Eye tracking system",
      "Fast & accurate",
      "Wide & touch screen",
      "Modern IOL power calculations",
      "Barrette Site",
      "Ray Tracing (Optional)",
    ],
    additionalInfo: [
      {
        id: 1,
        title: "Publications",
      },
      {
        id: 2,
        title: "Brochures",
      },
      {
        id: 3,
        title: "Technical Data (Compare)",
      },
    ],
    img: ["/images/cataract/optical.png"],
    video: [
      {
        imggallery: "/images/cataract/OA-2000.png",
        videoSrc: "/images/cataract/video_1.png",
        videoTitle: "Product Description",
        videoId: "RBBOSnYDNm8", // Video ID for YouTube
      },
      {
        imggallery: "/images/cataract/OA-2000.png",
        videoSrc: "/images/cataract/video_2.png",
        videoTitle: "User Guide",
        videoId: "T2M9hSswlIs", // Another Video ID
      },
      {
        imggallery: "/images/cataract/OA-2000.png",
        videoSrc: "/images/cataract/video_1.png",
        videoTitle: "Technical Overview",
        videoId: "F8bEEsCNPw8", // Another Video ID
      },
      // Add more videos as needed
    ],
  },
];

export const wavefront = [
  {
    id: 1,
    logo: "/images/cataract/CSO.png",
    model: "OSIRIS-T",
    name: "Osiris-T provides a corneal topographer combined with a total ocular aberrometer",
    main_points: [
      "Based unique pyramid sensor",
      "Real time Ocular wavefront measurement",
      "Densitometer",
      "Dynamic accommodation",
      "Toric lens assistance",
      "Pupillography",
      "Advance analysis of Tear film",
      "Various maps and analysis",
    ],
    additionalInfo: [
      {
        id: 1,
        title: "Publications",
      },
      {
        id: 2,
        title: "Brochures",
      },
      {
        id: 3,
        title: "Technical Data (Compare)",
      },
    ],
    img: ["/images/cataract/osiris.png"],
    video: [
      {
        imggallery: [
          "/images/cataract/imggallery_1.png",
          "/images/cataract/imggallery_2.png",
          "/images/cataract/imggallery_3.png",
        ],
        videoSrc: "/images/cataract/overview.png",
        videoTitle: "Overview Screen",
      },
    ],
  },
];

export const selectiveLaser = [
  {
    id: 1,
    logo: "/images/cataract/capsu.png",
    model: "Capsulaser",
    name: "CAPSULaser is a selective laser that creates a capsulotomy in less than a 1/3rd of a second.",
    main_points: [
      "Based unique pyramid sensor",
      "Real time Ocular wavefront measurement",
      "Densitometer",
      "Dynamic accommodation",
      "Toric lens assistance",
      "Pupillography",
      "Advance analysis of Tear film",
      "Various maps and analysis",
    ],
    additionalInfo: [
      {
        id: 1,
        title: "Publications",
      },
      {
        id: 2,
        title: "Brochures",
      },
      {
        id: 3,
        title: "Technical Data (Compare)",
      },
    ],
    img: ["/images/cataract/pavel.png"],
    advantage: [
      {
        id: 1,
        name: "CAPSULASER ADVANTAGE",
        points: [
          {
            id: 1,
            img: "/images/cataract/adv_1.png",
            desc: "Consistence Capsulotomies in every surgery",
          },
          {
            id: 2,
            img: "/images/cataract/adv_2.png",
            desc: "Laser precision for size and centration",
          },
          {
            id: 3,
            img: "/images/cataract/adv_3.png",
            desc: "An elastic capsulotomy rim resistant to tearing",
          },
          {
            id: 4,
            img: "/images/cataract/adv_4.png",
            desc: "A small ergonomic microscope mounted laser device",
          },
          {
            id: 5,
            img: "/images/cataract/adv_5.png",
            desc: "CAPSULASER provides a cost & time effective solution",
          },
        ],
      },
    ],

    video: [
      {
        imggallery: [
          "/images/cataract/imggallery_1.png",
          "/images/cataract/imggallery_2.png",
          "/images/cataract/imggallery_3.png",
        ],
        videoSrc: "/images/cataract/overview.png",
        videoUrl: "https://youtu.be/tADaSl_HtqA",
        videoTitle: "Surgical Video",
      },
    ],
  },
];

export const surgical = [
  {
    id: 1,
    logo: "/images/cataract/mani.png",
    model: "Mani Ophthalmic Knife",
    name: "Ophthalmic Knifes & Trocars",
    main_points: [
      "Ultimate sharpness",
      "Less induced Astigmatism",
      "Less tissue damage",
      "Faster patient recovery",
    ],
    additionalInfo: [
      {
        id: 1,
        title: "Publications",
      },
      {
        id: 2,
        title: "Brochures",
      },
      {
        id: 3,
        title: "Technical Data (Compare)",
      },
    ],
    img: ["/images/cataract/surgical_img.png"],
    video: [
      {
        videoSrc: "/images/cataract/surgical_video.png",
        videoUrl: "https://youtu.be/tADaSl_HtqA",
      },
    ],
  },
];

export const OtTableData = [
  {
    id: 1,
    model: "500 XLE",
    data: [
      {
        id: 1,
        logo: "/images/cataract/ufsk.png",
        model: "500 XLE",
        name: "Treatment Table",
        main_points: [
          "Efficient & Ergonomic",
          "Ergonomic lying surface",
          "Low entry position for the patient",
          "Removable push handles & armrests",
          "Optimal working conditions for the surgeon",
          "Robust steel chassis with four double castors",
        ],
        additionalInfo: [
          {
            id: 1,
            title: "Brochures",
          },
          {
            id: 2,
            title: "Technical Data (Compare)",
          },
        ],
        img: ["/images/cataract/500-XLE.png"],
        video: [
          {
            imggallery: [
              "/images/cataract/imggallery_1.png",
              "/images/cataract/imggallery_2.png",
              "/images/cataract/imggallery_3.png",
            ],
            videoSrc: "/images/cataract/overview.png",
            // videoTitle: "Overview Screen",
          },
        ],
      },
    ],
  },
  {
    id: 2,
    model: "Surgiline",
    data: [
      {
        id: 1,
        logo: "/images/cataract/DGH.png",
        model: "Scanmate A",
        name: "The innovative DGH 6000 A-Scan (Scanmate A) offers clinicians an unmatched level of usability and accuracy.",
        main_points: [
          "Ultra portable",
          "Unique measurement guidance",
          "Unique grading algorithm",
          "Contact & immersion mode",
          "Compression lock-out",
          "Multiple IOL power calculation",
        ],
        additionalInfo: [
          {
            id: 1,
            title: "Brochures",
          },
          {
            id: 2,
            title: "Technical Data (Compare)",
          },
        ],
        img: ["/images/cataract/500-XLE.png"],
        video: [
          {
            imggallery: [
              "/images/cataract/imggallery_1.png",
              "/images/cataract/imggallery_2.png",
              "/images/cataract/imggallery_3.png",
            ],
            videoSrc: "/images/cataract/overview.png",
            // videoTitle: "Overview Screen",
          },
        ],
      },
    ],
  },
];
