import Aos from "aos";
import React, { useEffect } from "react";

const BreadCrumb = ({ title, customCss, onClick }) => {
  return (
    <h1
      className={`text-white pl-[6.83vw] md:pt-[19vh] pt-[12vh] font-poppins font-regular cursor-pointer md:text-16px-vw text-12px-mvw opacity-80 ${customCss}`}
      onClick={onClick}
    >
      {title}
    </h1>
  );
};

export default BreadCrumb;
