import React from "react";
import Button from "./Button";
import { jsPDF } from "jspdf";

const DownloadCard = ({ data }) => {
  // Function to handle PDF download
  const handleDownloadPDF = (title) => {
    const doc = new jsPDF();

    // Add a title
    doc.setFontSize(22);
    doc.text("Sample PDF - " + title, 10, 20);

    // Add a subtitle
    doc.setFontSize(16);
    doc.text("Generated by  React below is dummy data", 10, 30);


    // Add lorem ipsum text in paragraphs
    doc.setFontSize(12);
    const loremText = `Lorem ipsum dolor sit amet, consectetur adipiscing elit. 
    Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, 
    quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. 
    Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
    Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.`;

    doc.text(loremText, 10, 50, { maxWidth: 180 }); // Text with word-wrap

    // Add more text in a new paragraph
    doc.text(
      `Curabitur pretium tincidunt lacus. Nulla gravida orci a odio. Nullam varius, turpis et commodo pharetra, 
    est eros bibendum elit, nec luctus magna felis sollicitudin mauris. Integer in mauris eu nibh euismod gravida. 
    Duis ac tellus et risus vulputate vehicula.`,
      10,
      90,
      { maxWidth: 180 }
    );

    // Save the PDF
    doc.save(`${title}.pdf`);
  };

  return (
    <>
      {data.map((item) => (
        <div
          key={item.id}
          className="w-full relative overflow-hidden cursor-pointer border-[1px] border-solid border-[#707070] group border-opacity-40 rounded-[1.6vw] md:mb-50px-vw"
        >
          <div
            className="absolute inset-0 bg-cover bg-center opacity-0 group-hover:opacity-100 transition-opacity duration-300"
            style={{ backgroundImage: "url('/images/investors/card-bg.png')" }}
          />
          <div className="flex flex-col text-[#2B2A29] font-poppins group-hover:text-white items-start md:p-40px-vw md:gap-y-12px-vw ">
            <div className="font-medium md:text-32px-vw group-hover:text-white z-10">
              0{item.id}
            </div>
            <div className="font-poppins font-medium text-32px-vw group-hover:text-white z-10">
              {item.title}
            </div>
            <div className="md:mt-40px-vw">
              {/* Button that triggers PDF download */}
              <Button
                title={"Download PDF"}
                className="border-[#707070]"
                customClasses={
                  "group-hover:border-white transition-colors duration-300"
                }
                onClick={() => handleDownloadPDF(item.title)} // Handle click event
              />
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default DownloadCard;
