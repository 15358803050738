import React from 'react'

const FooterSectionHeading = ({headingText,onClick,customCss}) => { 
  return (
    <div className='text-[#0B0B0B] font-raleway font-bold md:text-40px-vw md:py-30px-vw'>
   {headingText}
    </div>
  )
}

export default FooterSectionHeading